<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="8">
      <v-banner>
        <!-- <v-avatar slot="icon" color="deep-purple accent-4" size="40"> -->
        <!-- <v-icon icon="mdi-lock" color="white">
              mdi-account-alert
            </v-icon> -->
        <!-- </v-avatar> -->
        <v-icon slot="icon" color="warning" size="36">
          mdi-account-alert-outline
        </v-icon>
        <div
          class="d-flex clickable"
          @click="show = !show"
        >
          <span class="font-weight-bold py-2">
            Are you one of the {{ business.custtype.toLowerCase() }} of
            {{ business.name }}?
          </span>
          <v-spacer></v-spacer>
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </div>
        <v-expand-transition>
          <div v-show="show">
            <p>
              If yes, your FeePlus account has not been linked. Check with
              {{ business.name }} and make sure your registered email is the same with
              their customer record.
            </p>
          </div>
        </v-expand-transition>
      </v-banner>
    </v-col>
    <v-col cols="12" md="10" lg="8">
      <business-profile></business-profile>
    </v-col>
  </v-row>
</template>
<script>
import BusinessProfile from "@/components/BusinessProfile";

export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    fpUser() {
      return this.$store.state.fp_user;
    },
    business() {
      return this.$store.state.business;
    },
  },
  components: {
    BusinessProfile,
  },
};
</script>
<style scoped>
.clickable:hover {
  color: #2196f3;
  cursor: pointer;
}
</style>
